// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// let url = 'https://25ba-2405-201-1d-e0b9-193d-7201-9816-5d8e.in.ngrok.io/app/'; // dev
//let url = 'https://trupay.intrxn.com/app/'; // deploy
let domain = 'https://devbackend.intrxn.com/'; // deploy
// let domain = 'http://localhost:3001/'; // local
let url = domain+'app/'; //
// let url = 'http://localhost:3001/app/'; //

//
export const environment = {
  production: false,
  isprodEnv:false,
  externalAPIEndpoint: domain,
  widgetUrl:'https://dev-payouts.intrxn.com/',
  infuraID: 'a7b055698c834dd0a82490c250c36ed3',
  recaptcha_siteKey: '6LdxG_AeAAAAAKK5N0lC3_YIs3XY1-Sap_Si86Wn',
  firebaseConfig: {
    apiKey: 'AIzaSyBWJw6DTc80wU6VOeJiAMAJ4nAHde2M0bQ',
    //authDomain: "intrxn-auth.firebaseapp.com",
    authDomain: 'devauth.intrxn.com',
    projectId: 'intrxn-auth',
    storageBucket: 'intrxn-auth.appspot.com',
    messagingSenderId: '246628656609',
    appId: '1:246628656609:web:eb04cf97cbff111b789ea0',
    measurementId: 'G-GHXWK7X56J',
  },
  domain: domain,
  url: url,
  enableFERequest:false,
  s3Config: {
    access_key_id: 'AKIA6FMJR4WDQ2ML6H7W',
    secret_access_key: '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',
    region: 'me-south-1',
    bucketName: 'intrxn-dev',
  },

  ENV: 'PROD', // Please make it PROD for mainnet testing. 
  WEB3_DEFUALT: {
    NETWORK: 'MUMBAI',
    CHAINID: 80001,
    // SYSTEM_WALLET_ADDRESS: '0x7816C13a8476d0d87E918AC308C01E723a08FDaA',
    SYSTEM_WALLET_ADDRESS: '0x9D68b0E5e9B465630860C7984F2862C880a4eACE',
  },
  payouts: {
    onmetaAPIKey: '97d53624-d58f-4aa8-9c1f-2503f50a1493',
    onmeta: 'https://stg.api.onmeta.in/v1/',
    encryptus: 'https://sandbox.encryptus.co/v1/',
    onmetaKYCWidgetUrl:'https://stg.platform.onmeta.in/kyc',
    encryptusCredentials: {
      grant_services: ['FORENSICS'],
      clientID: 'c12efaac-8092-4c19-98c0-9e6893507677',
      clientSecret: '997dbad0-84b1-47ad-9eca-55fcdefd5b10',
      partnerEmail: 'hemant@intrxn.com',
      partnerPassword: 'intrXnTech_@2023',
    },
  },
  externalPayment: 1,
  paymentKey: 'd0f46c91-dd79-4ad5-ba67-2441322a98e2'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
