<div class="gws_container">
  <div class="gws_comp_profile gws_side_profile">
    <span class="gws_profile_badge">
      {{ busNameTxt }}
    </span>
    <h6>{{ businessName }}</h6>
  </div>
  <div class="gws_side_menu_items">
    <div class="gws_menu_item">
      <mat-nav-list>
        <ng-container>
          <a mat-list-item disableRipple class="menus gws_mat-list-item" [routerLink]="['/dashboard']"
            routerLinkActive="active">
            <mat-icon aria-hidden="true" aria-label="Example home icon">dashboard</mat-icon>
            Dashboard
          </a>
          <a mat-list-item disableRipple class="menus gws_mat-list-item" routerLinkActive="active">
            <span class="nav-link" (click)="handleAction('/invoices/list/invoices-sent')">
              <mat-icon aria-hidden="true" aria-label="Example home icon">article</mat-icon>
              <span>Invoices</span>
            </span>

            <mat-icon (click)="showDetails('Invoices')" class="smallicn" aria-hidden="true">info
            </mat-icon>
          </a>

          <!-- <a mat-list-item disableRipple class="gws_mat-list-item" [routerLink]="['/invoice']"
                    routerLinkActive="active">
                    <mat-icon aria-hidden="true" aria-label="Example home icon">article</mat-icon>
                    Invoice
                </a> -->
          <a mat-list-item disableRipple class="menus gws_mat-list-item" routerLinkActive="active">
            <span class="nav-link" (click)="handleAction('/payment-link')">
              <mat-icon aria-hidden="true" aria-label="Example home icon">payments</mat-icon>
              <span>Payment Link</span>
            </span>
            <mat-icon (click)="showDetails('Payment Link')" class="smallicn" aria-hidden="true">info
            </mat-icon>
          </a>

          <a mat-list-item disableRipple class="gws_mat-list-item" [routerLink]="['/offers']" routerLinkActive="active">
            <mat-icon aria-hidden="true" aria-label="Example home icon" fontIcon="redeem">redeem
            </mat-icon>
            Offers
          </a>
          <a mat-list-item disableRipple class="gws_mat-list-item" [routerLink]="['/subscriptions']" routerLinkActive="active" >
            <mat-icon aria-hidden="true" aria-label="Example home icon" fontIcon= "card_membership">card_membership</mat-icon>
            Subscriptions
        </a>
          <a mat-list-item disableRipple class="menus gws_mat-list-item" routerLinkActive="active">
            <span class="nav-link" (click)="handleAction('/fiat-withdrawals')">
              <mat-icon aria-hidden="true" aria-label="Example home icon">swap_horizontal_circle
              </mat-icon>
              <span>FIAT withdrawals</span>
            </span>
            <!-- <mat-icon (click)="showDetails('FIAT withdrawals')" class="smallicn" aria-hidden="true">info
            </mat-icon> -->
          </a>

          <a mat-list-item disableRipple class="menus gws_mat-list-item" [routerLink]="['/customers']"
            routerLinkActive="active">
            <mat-icon aria-hidden="true" aria-label="Example home icon">group</mat-icon>
            Customers
          </a>
          <a mat-list-item disableRipple class="menus gws_mat-list-item" [routerLink]="['/products']"
            routerLinkActive="active">
            <mat-icon aria-hidden="true" aria-label="Example home icon">featured_play_list</mat-icon>
            Products
          </a>
          <a mat-list-item disableRipple class="menus gws_mat-list-item" [routerLink]="['/api']"
            routerLinkActive="active">
            <mat-icon aria-hidden="true" aria-label="Example home icon">key</mat-icon>
            For Developers
          </a>
          <a mat-list-item disableRipple class="menus gws_mat-list-item" [routerLink]="['/referral-program']"
            routerLinkActive="active">
            <mat-icon aria-hidden="true" aria-label="Example home icon">groups</mat-icon>
            Referral Program
          </a>
          <a mat-list-item disableRipple class="menus gws_mat-list-item" [routerLink]="['/settings']"
            routerLinkActive="active">
            <mat-icon aria-hidden="true" aria-label="Example home icon">settings</mat-icon>
            Settings
          </a>
        </ng-container>
      </mat-nav-list>
    </div>
  </div>
</div>